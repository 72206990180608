import React from 'react';
import { css } from '@emotion/core';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Emoji from '../components/emoji';

const OrderComplete = props => {
  return (
    <Layout>
      <SEO title="Order Complete" />
      <div
        css={css`
          background-color: #fff6ff;
          border-radius: 25px;
          margin: 25px;
          padding: 25px;
          text-align: center;
          p {
            margin: 20px;
          }
        `}
      >
        <p>
          Order complete! <Emoji label="Woo hoo!" emoji="🎉" />
        </p>
        <p>We'll get your order printing ASAP.</p>
      </div>
    </Layout>
  );
};
export default OrderComplete;
